import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule, HttpLoaderFactory } from './shared/shared.module';
import { CookieService } from 'ngx-cookie-service';

import { AppHeader } from './component/header.component';
import { Sidemenu } from './component/side_menu.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MapService } from './shared/Services/mapService/map-service';
import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BsDropdownModule,SortableModule } from 'ngx-bootstrap';

  @NgModule({
  declarations: [
    AppComponent,
    AppHeader ,
    Sidemenu
  ],
  imports: [ 
    HttpClientModule,
    SharedModule,
    BrowserModule,
    AmazingTimePickerModule,
    BrowserAnimationsModule,
   
    ToastrModule.forRoot(),    
    RouterModule.forRoot(appRoutes),
    BsDropdownModule.forRoot(),
    SortableModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
  ],
  providers: [MapService,CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
