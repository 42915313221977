import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient } from '@angular/common/http';

//services
import { CookieService } from 'ngx-cookie-service';
import { MenuLinkService } from '../menu/menu-link.service';
import { Configuration } from '../../configurations/shared.config';
import { MenuLinks } from '../../Resources/menu.res';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable()
export class AuthenticationService {
    userName: string;//= this.getCurrentuserName();
    UserId: string;//= this.getCurrentUserId();
    SubId: string;//= this.getCurrentSubId();
    StaffId: string;//=this.getCurrentStaffId();
    culture: string;// = this.getCurrentculture();
    subLogo: string;// = this.getSublogo();
    permittedBranches: any[];//=this.getPermittedBranches(this.UserId) ;
    constructor(private CookieSrvc: CookieService,
      private menuservice: MenuLinkService,
      private http: HttpClient,
      private route: ActivatedRoute,
      private authorizationService : AuthorizationService) {
        // this.initializeObject();

    }
    isLogged: boolean = false;
    loggedIn$ = new BehaviorSubject<boolean>(this.isLogged);




    //     getPermittedBranches(userId:string)
    //     {
    //         let result : any[];
    //       this.getPermittedBranchesForUser(userId).subscribe((res) => {
    //              return res;
    //       });
    //       return result;
    //   }
    getPermittedBranchesForStaff(StaffId: string) {
        let Params = new HttpParams();
        Params = Params.append('StaffId', StaffId);

        return this.http.get<any[]>(Configuration.urls.webApiEndpoint + Configuration.urls.apiLookupsUrls.apigetPermittedBranchesForStaffURL, { params: Params });

    }
    async initializeObject() {
        // this.userName = this.getCurrentuserName();
        this.UserId = this.getCurrentUserId();
        this.SubId = this.getCurrentSubId();
        this.StaffId = this.getCurrentStaffId();
        this.culture = this.getCurrentculture();
      this.subLogo = this.getSublogo();
      debugger
        const userName = localStorage.getItem('userName');
        if(userName != undefined && userName != "" && userName != null){
          this.userName = userName;
        }
    }



    getCurrentUser() {
        if (this.CookieSrvc.get('SSOCookie')) {
            try {
                return this.CookieSrvc.get('SSOCookie')
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }
    getCurrentUserPermission() {
        if (this.CookieSrvc.get('SSOPCookie')) {
            try {
                var cooki = this.CookieSrvc.get('SSOPCookie');
                var currentPath = this.getPropertyByValue(MenuLinks, window.location.href.replace(Configuration.Ports.PostalPort, ""))
                var page = cooki.split("*").find(x => x.includes(currentPath));
                if (page != null)
                    return page.split(":")[1]
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }
    getSublogo() {
        let Cookie: string = this.getCurrentUser();
        if (Cookie)
            return Configuration.Ports.AltairPort + Cookie.split('*')[8].replace('~', '');
        else return "";

    }

    validUser() {
        var userdata = this.getCurrentUser();
        if (userdata != null) {
          const userName = localStorage.getItem('userName');
          if(userName != undefined && userName != "" && userName != null){
            this.userName = userName;
          }else{
            this.GetCurrentuserNameByUserId();
          }
            // this.userName = userdata.split('*')[14];
            //debugger
            // Rmazy: need to make sure that all pages are added to the pages and user permissins in DB
            //var tst = this.getCurrentUserPermission();
            // if (this.getCurrentUserPermission() == null) {
            //     this._setLoggedIn(false);
            //     return false;
            // }

            this._setLoggedIn(true);
            return true;
        }
        this._setLoggedIn(false);
        return false;
    }

    getPropertyByValue(obj, valueToCheck) {
        for (const prop in obj) {
            if (obj[prop] != null && obj[prop] === valueToCheck) {
                return prop.toString();
            }
        }
        return null;
    }

    private _setLoggedIn(value: boolean) {
        // Update login status subject
        if (this.isLogged != value) {
            this.loggedIn$.next(value);
            this.isLogged = value;
        }

    }

    getCurrentUserId(): string {

        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 2)
                return userdataarray[0]
            else
                return '';
        }
        return '';
    }
    getCurrentSubId(): string {

        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 2)
                return userdataarray[1]
            else
                return '';
        }
        return '';
    }
    getCurrentStaffId(): string {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 11) {

                //this.getPermittedBranchesForStaff(userdataarray[12]).subscribe((res) => {
                //    this.permittedBranches= res;
                //});
                return userdataarray[12]
            }
            else
                return '';
        }
        return '';
    }
    private getCurrentuserName(): string {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 14)
                return userdataarray[14]
            else
                return '';
        }
        return '';
    }

  GetCurrentuserNameByUserId() {
    debugger
      const UserId = this.getCurrentUserId();
    const userName = localStorage.getItem('userName');

    if ((UserId != undefined && UserId != null) &&
      (userName == undefined || userName == "" || userName == null)) {
           this.authorizationService.getUserName(UserId).subscribe(res => {
            this.userName = res;
            localStorage.setItem('userName', res);
           });
      }
  }

    getCurrentculture(): string {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 3) {
                let culture = userdataarray[4].split('-')
                return culture[0]
            }
            else
                return 'en';
        }
        return 'en';
    }
    setCulture(culture: string) {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: string[] = userdata.split('*');
            if (userdataarray.length > 3) {
                userdataarray[4] = culture;
                let CookieDomain = (Configuration.keys.CookieDomain && Configuration.keys.CookieDomain != '') ? Configuration.keys.CookieDomain : null
                this.CookieSrvc.set('SSOCookie', userdataarray.join('*'), null, '/', CookieDomain);
            }
        }
    }

    getToken() {
        if (this.CookieSrvc.get('Authentication')) {
            try {
                return this.CookieSrvc.get('Authentication')
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }

    logout() {
        this.removeUser();
        window.location.href = this.menuservice.getLink('login', 'Altair');
    }
    removeUser() {
        let CookieDomain = (Configuration.keys.CookieDomain && Configuration.keys.CookieDomain != '') ? Configuration.keys.CookieDomain : null
        this.CookieSrvc.deleteAll('/', CookieDomain);
        this._setLoggedIn(false);
    }

    updateToken(token: string) {
        let CookieDomain = (Configuration.keys.CookieDomain && Configuration.keys.CookieDomain != '') ? Configuration.keys.CookieDomain : null
        this.CookieSrvc.set('Authentication', token, null, '/', CookieDomain);
    }
}
