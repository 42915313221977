import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Configuration } from './shared/configurations/shared.config';
// import { pageNotFound } from './shared/components/pageNotFound/pageNotFound.component';
import { AuthGuard } from './shared/Providers/auth-guard.provider';

export const appRoutes: Routes = [

    {
        path: '',
        redirectTo: 'workorder/staticroute',
        pathMatch: 'full'
    },
    {
        path: Configuration.modulePrefixes.WorkorderManagement,
        loadChildren: './workOrder/workOrder.module#WorkOrderModule',
        canActivate: [AuthGuard]
    }, 
    {
        path: '',
        redirectTo: 'controlpanel/workordertype',
        pathMatch: 'full'
    },
    {
        path: Configuration.modulePrefixes.ControlPanel,
        loadChildren: './controlpanel/controlpanel.module#ControlpanelModule',
        canActivate: [AuthGuard]
    },
    
    {
        path: '',
        redirectTo: 'controlpanel/loadtype',
        pathMatch: 'full'
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
