import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

//services
import { AuthenticationService } from '../Services/authentication/authentication.service';
import { MenuLinkService } from '../Services/menu/menu-link.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private menuservice: MenuLinkService, private auth: AuthenticationService, private route1: Router) {
    this.auth.initializeObject();
    //debugger
    //console.log(">>>" + this.route1);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    // if the user is not logged in return
    if (!this.auth.validUser()) {
      window.location.href = this.menuservice.getLink('login', 'Altair');
      return false;
    }
    else {
      return true;
    }
  }
}
