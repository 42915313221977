export let Configuration = {
    Ports: {
        AltairPort: 'http://localhost:4679',
        GpsPort: 'http://localhost:9000',
        RenCarsPort: 'http://localhost:32067',
        PostalServiceApi: 'http://localhost:52994',
        PostalPort: 'http://localhost:55760',
        SchoolPort: 'http://localhost:32597'

    },
    defaultMapParams: {
        zoom: 10,
        mapType: 'google',
        centerX: 31.259443,
        centerY: 30.099036,
        minZoom: 2,
        maxZoom: 20
    },
    tokens:
    {
        GoogleMapDirectionsToken: 'AIzaSyB_HWxIIAw5vdXy4zgFh3KKEj6BAc9MWI4'
    },
    urls: {
        webApiEndpoint: "http://localhost:52994/api/",
        GoogleMapUrl: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        ESRIMapUrl: 'https://server.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        calculateRouteUrl: 'MapUtils/calculateRoute',

        apiAccountUrl: {
            apiGetPermissionsURL: 'account/staffPermissions',
            apiGetUserName: 'account/GetUserName'
        },
        cashe: {
            apiGetVehiclehistoryUrl: 'cache/getvehiclehistory',
            apiGetViewFuelConsumptionUrl: 'cache/getViewFuelConsumption',
            apiGetViewFuelConsumptionDetailUrl: 'cache/getViewFuelConsumptionDetails',
            apiGetViewFuelConsumptionDriverUrl: 'cache/getViewFuelConsumptiondriver',
            apiGetFuelConsumptionExportToExcelListUrl: 'cache/getFuelConsumptionExportToExcelList',
            apiGetFuelConsumptionDriverExportToExcelListUrl: 'cache/getFuelConsumptionDriverExportToExcelList'
        },
        apiOperationWorkOrderListUrls: {
            apiGetOperationWorkOrderListURL: 'workorder/operationWorkorderlist',
            apiDeleteOperationWorkOrderURL: 'workorder/deleteoperationworkorder',
            apiGetOperationWorkOrderPrintInfoURL: 'workorder/operationworkorderprintinfo',
            apiGetOperationWorkOrderExportToExcelListURL: 'workorder/operationWorkorderexporttoexcellist',
            apiGetOperationWorkOrderPrintedSerialURL: 'workorder/getopwoprintedserial',
            apiSetOperationWorkOrderPrintedSerialURL: 'workorder/setopwoprintedserial',
            apiGetTransportersWOLastOdometerURL: 'workorder/gettransporterswolastodometer',
            apiWOTransporterCheckAvailabilityURL:'workorder/checktransporteravailability',

        },
        apiDashboardUrls: {
            apiGetLeastUsedVehiclesPerWOsURL: 'dashboard/getleastusedvehiclesperwos',
            apiGetTopUsedVehiclesPerWOsURL: 'dashboard/gettopusedvehiclesperwos',
            apiGetTopVehiclesCountsPerRoutesURL: 'dashboard/gettopvehiclescountsperroutes',
            apiGetTopWOsTypesURL: 'dashboard/gettopwostypes',
            apiGetWorkOrderRouteComplianceURL: 'dashboard/getworkorderroutecompliance',
            apiGetWorkOrderStatusURL: 'dashboard/getworkorderstatus',
        },
        apiOperationWorkOrderReportUrls: {
            apiRouteViolationReportURL: 'workorderReport/getrouteviolationreport',
        },
        apiRouterUrls: {
            apiPostRouter: 'router/PostRoute',
            getRouteByIdUrls: 'router/getRouteByID',
            getRouteLandmarkWithLoadDataByIDUrls: 'router/getRouteLandmarkWithLoadDataByID',
            apiGetLandmarktoptenURL: 'router/getLandmarktopten',
            apiGetLandmarktoptenEmptyURL: 'router/getLandmarktoptenEmpty',
        },
        apiLookupsUrls: {
            apiGetLandmarkURL: 'lookups/getLandmarks',
            apiGetBranchURL: 'lookups/GetBranches',
            apiGetBranchListWithStaffIdURL: 'lookups/getBranchListWithStaffId',
            apiGetSubBranchListWithStaffIdURL: 'lookups/getSubBranchListWithStaffId',
            apiGetSubBranchesURL: 'lookups/getSubBranches',
            apiGetRouteURL: 'lookups/GetRouteLookup',
            apiGetWorkOrderStatusesURL: 'lookups/getopwostatus',
            apiGetDriversURL: 'lookups/getdrivers',
            apiGetDriversByBranchURL: 'lookups/getdriversByBranch',
            apiGetDriversByBranchAndSubBranchURL: 'lookups/getdriversByBranchAndSubBranch',
            apiGetTransportersURL: 'lookups/gettransporters',
            apiGetTransporterGroupURL: 'lookups/gettransportergroup',
            apiGetTransporterTypesURL: 'lookups/gettransportertype',
            apiGetTransportersByBranchURL: 'lookups/gettransportersByBranch',

            apiGetTransportersByBranchAndSubBranchURL: 'lookups/gettransportersByBranchAndSubBranch',
            apiWOTypesURL: 'lookups/getopwotypes',
            apiGetLoadTypesURL: 'lookups/getLoadtypes',
            apiGetTransporterStaffURL: 'lookups/getTransporterStaffList',
            //apiGetBranchURL: 'lookups/GetBranches',
            //apiGetRouteURL: 'lookups/GetRouteLookup',
            //apiGetWorkOrderStatusesURL: 'lookups/getWorkOrderStatuses',

            apiGetWorkOrderCodesURL: 'lookups/getopwocodes',
            apiGetWorkOrderTypesURL: 'lookups/getopwotypes',
            //apiGetTransportersURL: 'lookups/gettransporters',
            apiGetWorkOrderStatusURL: 'lookups/getopwostatus',
            //apiGetDriversURL: 'lookups/getdrivers',
            apiGetWorkOrderListPeriodTypeURL: 'lookups/getopwolistperiodtype',
            apiRouteViolationReportURL: 'lookups/getrouteviolationreportperiodtype',
            apiGetDashboardPeriodTypeURL: 'lookups/getdashboardperiodtype',
            // apiGetBranchURL: 'lookups/GetBranches',
            // apiGetSubBranchesURL: 'lookups/getSubBranches',
            //apiGetRouteURL: 'lookups/GetRouteLookup',
            // apiGetWorkOrderStatusesURL: 'lookups/getopwostatus',
            //  apiGetDriversURL: 'lookups/getdrivers',
            // apiGetTransportersURL: 'lookups/gettransporters',
            //  apiWOTypesURL: 'lookups/getopwotypes',
            // apiGetTransporterStaffURL: 'lookups/getTransporterStaffList',
            apiGetAllStaffURL: 'lookups/getAllStaff',
            apiGetAllStaffByBranchURL: 'lookups/getAllStaffByBranch',
            apiGetCancelreasonsURL: 'lookups/getCancelReasons',
            apigetRoutetakeTopURL:'lookups/getRouteLookup_AutoComplete',
            apigetRoutetakeTopEmptyURL:'lookups/getRouteLookup_AutoComplete_Empty',
            apiGetServerDateTimeBySubId:'lookups/getserverdatetimebysubid',
            apigetPermittedBranchesForStaffURL:'account/getPermittedBranchesForStaff',
            apiGetUnitTypeBySubId :'lookups/getunittypebysubid'
        },
        apiWorkordersUrls: {
            apiGetWorkorderByID: 'workorder/GetWorkOrderByID',
            apiPostWorkOrder: 'workorder/PostWorkOrder',
            apiPostWorkOrderStatus: 'workorder/PostWorkOrderStatus',
            apiGetWorkOrderLandmarks: 'workorder/GetWorkOrderLandmarks',
            apiGetWorkOrderResources: 'workorder/GetWorkOrderResources',
            apiGetWorkOrderStatusLog: 'workorder/GetWorkOrderStatusLog',
            apiGetWorkOrderStatus: 'workorder/GetWorkOrderStatusByID',
            apigetTransporterOdometerReading:'workorder/getTransporterOdometerReading',
        },
        apiUpdateNormaUrls:{
            apiPostWorkOrderUpdateNorma:'updatenorma/UpdateNormaWO'

        },
        apiControlPanelUrls:{
            apiGetWorkOrderTypeList: 'controlpanel/workordertypelist',
            apiDeleteWorkOrderType: 'controlpanel/deleteworkordertype',
            apiPostWorkOrderType: 'controlpanel/postWorkOrderType',

            apiGetLoadTypeList :'loadtype/getLoadtypesList',
            apiDeleteLoadType :'loadtype/deleteloadtype',
            apiPostLoadType :'loadtype/postloadtype',
            apiGetLoadTypeById:'loadtype/getloadtypebyid',
        }

        // apiWorkordersUrls: {
        //     apiGetWorkorderByID: 'workorder/GetWorkOrderByID'
        //}

        // },

    },
    modulePrefixes: {
        WorkorderManagement: 'workorder',
        ControlPanel :'controlpanel',
        //LoadType :'loadtype',
        //WorkOrderType:'workordertype'

    },

    keys: {

        //planning service Keys
        LogisticsServiceToken: 'IBrkRtJb3z6Bp2sEGCAVIQBfZPCRGwhDxvMqMS1RtVnzz0O356IMaSekOQDjQ9WGHo-MUCYanlkLgieO8ePONcmytud7N553mfvSU-CjAdc0gIzEZm3uBKtVNjgE7wPdio0ptHLYrL9aIB-WKWmdIg..',
        LogisticsServiceDirectionsLanguage: "en",
        UploadExtension: "JPG,JPEG,TIF,GIF,PNG,BMP",
        maxFileSize: 5242880,
        LogisticsServiceTimeUnits: 'Minutes',
        LogisticsServiceDistanceUnits: 'Kilometers',
        LogisticsServiceUturnPolicy: 'NO_UTURNS',
        LogisticsServicePopulateDirections: "true",
        //cookie Domain
        CookieDomain: ""

    },

    messages: {
        defaultErrorMessage: 'unexpectedErrorMessage'
    },

    paths: {
        loginUrl: '/users/login',
        staticrouteList: '/workorder/staticroute',
        fuelsummaryList: '/workorder/fuelsummary',
        newrouteList: '/workorder/newroute',
        WorkOrderList: '/workorder/searchwo',
        newworkorder: '/workorder/addworkorder/',
        viewworkorder: '/workorder/viewworkorder/',
        //controlpanel:'/controlpanel/controlpanel',
        loadtypelist:'/controlpanel/loadtype',
        workordertypelist:'/controlpanel/workordertype'
    },
    GridSetting: {
        Pagesize: 50
    },
    Nagm: {
        IsTransactionStatusChangeEditable: false,
        RFRLink: "/WebPages/RequestForRepair/Create.aspx",
        RFRAccidentLink: "/WebPages/RequestForRepair/AccidentCreate.aspx"

    },

    Logo: {
          //src: '/dist/assets/PostalBranding/styles/img/logo-g.png'
          src: '/dist/assets/TahdeedBranding/styles/img/TahdeedLogo.svg'
        // src: '/dist/assets/fmsBranding/styles/img/logo.png'
    }

};

