import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap';
import { AuthenticationService } from '../../Services/authentication/authentication.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {
  @Input() selecteddate: Date ;
  @Output() selecteddateChange:EventEmitter<Date> = new EventEmitter<Date>();
  @Input() viewOnly : boolean = false;
  @Input() id:any = "date1";
  datePickerConfig = {
    containerClass: this._tran.currentLang == 'ar' ? ' rtl' : ' '
  }
  constructor(private _tran: TranslateService, private localeService: BsLocaleService, private auth: AuthenticationService) {
    this.auth.initializeObject();

  }

  ngOnInit() {
    this.localeService.use(this.auth.getCurrentculture());
      this.datePickerConfig = {
        containerClass: this._tran.currentLang == 'ar' ? 'theme-red rtl' : 'theme-red'
      }
    this._tran.onLangChange.subscribe((event) => {
     // debugger
      this.localeService.use(this.auth.getCurrentculture());
      this.datePickerConfig = {
        containerClass: this._tran.currentLang == 'ar' ? 'theme-red rtl' : 'theme-red'
      }
    });

  }
  onChange(val :any ){
    //debugger
    if(val != null)
    this.selecteddateChange.emit(val);
  }
}
