import { Component, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-report-violation-fillter',
  templateUrl: './report-violation-fillter.component.html',
  styleUrls: ['./report-violation-fillter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportViolationFillterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
