import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// custom module
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
 import { defineLocale } from 'ngx-bootstrap/chronos';
 import {  arLocale ,enGbLocale } from 'ngx-bootstrap/locale';

 defineLocale('ar', arLocale);  
 defineLocale('en', enGbLocale);  
// Pipes
import { MenuLinkPipe } from './pipes/menu-link.pipe';

//services
import { MenuLinkService } from './Services/menu/menu-link.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from './Services/authorization/authorization.service';
import { AuthenticationService } from './Services/authentication/authentication.service';
 

import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal'; 
// component
// import { pageNotFound } from './components/pageNotFound/pageNotFound.component';
// import { alertComponent } from './components/alert/alert.component';
// import { CommonMapComponent } from './components/commonMap/common-map.component';

//directives
// import { CompareValidatorDirective } from './directives/compare-validator.directive';
//providers
import { AuthGuard } from './Providers/auth-guard.provider';


//translation
import { Ng5SliderModule } from 'ng5-slider';
import {HttpClient} from '@angular/common/http';
import {TranslateModule ,TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../dist/assets/i18n/', '.json');
}

// interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from './Providers/authHttpInterceptor';
import { OnlyNumber } from './pipes/OnlyNumber';
import { PaginationComponentComponent } from './component/pagination-component/pagination-component.component';
import { CommonMapComponent } from './component/commonMap/common-map.component';
import { DatepickerComponent } from './component/datepicker/datepicker.component';
import { AlertmessageComponent } from './component/alertmessage/alertmessage.component';
import { AmazingTimepickerComponent } from './component/amazing-timepicker/amazing-timepicker.component';
import { ReportViolationFillterComponent } from './component/report-violation-fillter/report-violation-fillter.component';
import { PlaybackComponent } from './component/playback/playback.component';

defineLocale('ar', arLocale);  
defineLocale('en', enGbLocale); 
 
let providers = [
    MenuLinkService, 
    CookieService, 
    // LoaderService, 
    AuthorizationService, 
    AuthenticationService, 
    AuthGuard,
    BsModalRef,
    BsModalService
   ,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true
    }
]

let sharedDeclarations = [
    MenuLinkPipe, 
    OnlyNumber,
    PaginationComponentComponent ,CommonMapComponent,
    DatepickerComponent,
     AlertmessageComponent,
     AmazingTimepickerComponent,
     ReportViolationFillterComponent,
     PlaybackComponent
    // pageNotFound, CompareValidatorDirective, alertComponent,
    //     BulkUpload,
    //     CommonMapComponent , PlaybackComponent,
    //     GuardiansUpload,
    //     UploadfilesComponent,
];

@NgModule({
    declarations: [sharedDeclarations, ReportViolationFillterComponent],
    imports: [
        CommonModule,
        FormsModule,
        AngularMultiSelectModule,
        AmazingTimePickerModule ,
       
        PaginationModule.forRoot(),
        AlertModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        Ng5SliderModule ,
        PaginationModule.forRoot(),
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
          })
    ],
    providers: providers,
    exports: [sharedDeclarations, AngularMultiSelectModule,  BsDatepickerModule,PaginationModule ,  TranslateModule ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: providers
        }
    }
}
