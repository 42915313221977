import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Modules } from '../shared/enums/modules.enum';
import { PermissionDataModel } from '../shared/datamodels/permissions.data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'side-menu',
  templateUrl: './side_menu.component.html'

})
export class Sidemenu implements OnInit {
  constructor(private Translate: TranslateService) {

  }
  @Input() Permissions: PermissionDataModel[] = [];
  @Output() subMenuState: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isSubMenuOpend: boolean = false;
  subMenuHeader: any = {};
  ModuleNames = Modules

  ResourcesLinks = [
    {
      key: 'FMSDashboard',
      site: 'Altair',
      resKey: 'Res_Dashboard'
    },
    {
      key: 'Transporters',
      site: 'Altair',
      resKey: 'Res_Vehicles'
    },
    {
      key: 'Staff',
      site: 'Altair',
      resKey: 'Res_Staff'
    },
    {
      key: 'StructureAndDistribution',
      site: 'Altair',
      resKey: 'Res_Structure'
    }
  ]
  MaintenanceLinks = [
    {
      key: 'RequestForRepair',
      site: 'Altair',
      resKey: 'maint_RFR'
    },
    {
      key: 'AccidentRequestForRepair',
      site: 'Altair',
      resKey: 'maint_Accident'
    },
    {
      key: 'PMRequestForRepair',
      site: 'Altair',
      resKey: 'maint_PM_RFR'
    },
    {
      key: 'CMRequestForRepair',
      site: 'Altair',
      resKey: 'maint_CM_RFR'
    },
    {
      key: 'WorkOrder',
      site: 'Altair',
      resKey: 'maint_WorkOrder'
    },
    {

      key: 'VendorWorkOrder',
      site: 'Altair',
      resKey: 'maint_Vendor'
    },
    {

      key: 'PreventiveMaintenance',
      site: 'Altair',
      resKey: 'maint_Preventive'
    },
    {
      key: 'lnk_MaintenanceDashboard',
      site: 'Altair',
      resKey: 'maint_Dashboard'
    }
  ]
  OperationsLinks = [
    
    {
      key: 'postalroutes',
      site: 'Postal',
      resKey: 'oper_Routes'
    },
    {
      key: 'postalworkorder',
      site: 'Postal',
      resKey: 'oper_WorkOrders'
    },
    {
      key: 'postaldashboard',
      site: 'Postal',
      resKey: 'Dashboard'
    }
,
    {
      key: 'Routes',
      site: 'Altair',
      resKey: 'oper_Routes'
    },
    {
      key: 'WorkOrderList',
      site: 'Altair',
      resKey: 'oper_WorkOrders'
    },
    {
      key: 'WorkOrderCostList',
      site: 'Altair',
      resKey: 'oper_Monitor'
    }
  ]
  GPSLinks = [
    {
      key: 'Map',
      site: 'GPS',
      resKey: 'Gps_LiveTracking'
    },
    {
      key: 'MapMonitor',
      site: 'GPS',
      resKey: 'Gps_FleetMonitoring'
      },
      {
          key: 'Landmarks',
          site: 'Altair',
          resKey: 'oper_Landmarks'
      },
    {
      key: 'Geofences',
      site: 'GPS',
      resKey: 'Gps_Geofences'
    },
    {
      key: 'GroupManagment',
      site: 'GPS',
      resKey: 'Gps_Group'
    },
    {
      key: 'GPSDashboard',
      site: 'School',
      resKey: 'GPS_DashboardKey'
    }
  ]
  RentCarsLinks = [
    {
      key: 'CarRentDashBoard',
      site: 'CarRent',
      resKey: 'Rent_Dashboard'
    },
    {
      key: 'PricePlanList',
      site: 'CarRent',
      resKey: 'Rent_PricePlans'
    },
    {
      key: 'AssignPricePlanToVehicles',
      site: 'CarRent',
      resKey: 'Rent_AssignPrice'
    },
    {
      key: 'PromotionsList',
      site: 'CarRent',
      resKey: 'Rent_Promotion'
    },
    {
      key: 'AdditionalServices',
      site: 'CarRent',
      resKey: 'Rent_AdditionalServices'
    },
    {
      key: 'Customers',
      site: 'CarRent',
      resKey: 'Rent_Customers'
    },
    {
      key: 'RentCar',
      site: 'CarRent',
      resKey: 'Rent_RentVehicle'
    },
    {
      key: 'ReturnPage',
      site: 'CarRent',
      resKey: 'Return Vehicle'
    },
    {
      key: 'ContractsList',
      site: 'CarRent',
      resKey: 'Rent_RentContracts'
    }
  ]
  Schools = [
    {
      key: 'PlanList',
      site: 'School',
      resKey: 'Sco_PlansManage'
    },
    {
      key: 'StudentList',
      site: 'School',
      resKey: 'Sco_StudentsManage'
    },
      {
          key: 'TripList',
          site: 'School',
          resKey: 'Sco_TripsManage'
      },
      {
          key: 'Dashboard',
          site: 'School',
          resKey: 'Sco_LiveDashboard'
      }
  ]
  ReportsLinks = [

    {
      key: 'GeneralReports',
      site: 'Altair',
      resKey: 'rep_General'
    },
    {
      key: 'GPSReports',
      site: 'GPS',
      resKey: 'rep_GPS'
    },
    {
      key: 'MaintenanceReports',
      site: 'Altair',
      resKey: 'rep_Maintenance'
    },
    {
      key: 'OperationReport',
      site: 'Altair',
      resKey: 'rep_Operations'
    },
    {
      key: 'RentalReports',
      site: 'CarRent',
      resKey: 'rep_RentCars'
    } 
   
  ]

  subMenuLinks = this.ResourcesLinks;

  ngOnInit() {
  }
  onModuleClick(moduleHeader) { 
    //debugger
    this.subMenuHeader = {
      icon: moduleHeader.icon,
      name: this.Translate.instant(moduleHeader.resKey)
    }
    this.isSubMenuOpend = !this.isSubMenuOpend;
    this.subMenuState.emit(this.isSubMenuOpend);
    switch (moduleHeader.Key) {
      case Modules.Resources:
        this.subMenuLinks = this.ResourcesLinks.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      case Modules.Maintenance:
        this.subMenuLinks = this.MaintenanceLinks.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      case Modules.Operations:
        this.subMenuLinks = this.OperationsLinks.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      case Modules.GPS:
        this.subMenuLinks = this.GPSLinks.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      case Modules.RentCars:
        this.subMenuLinks = this.RentCarsLinks.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      case Modules.Reports:
        this.subMenuLinks = this.ReportsLinks.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      case Modules.Schools:
        this.subMenuLinks = this.Schools.filter(x => this.Permissions.find(p => p.PageUniqueName.indexOf(x.key) !== -1));
        break;
      default:
        this.subMenuLinks = []
    }
  }

  showHeader(moduleName: string) {
    return this.Permissions.find(p => p.ModuleUniqueName === moduleName);
  }

  hideSubmenu() {
    if (this.isSubMenuOpend) {
      this.isSubMenuOpend = false;
      this.subMenuState.emit(this.isSubMenuOpend);
    }
  }
}
