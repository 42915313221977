import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { AuthenticationService } from '../../Services/authentication/authentication.service';
import { BsLocaleService } from 'ngx-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-amazing-timepicker',
  templateUrl: './amazing-timepicker.component.html'

})
export class AmazingTimepickerComponent implements OnInit {
  @Input() selectedTime:string;
  @Output() selectedTimeChange : EventEmitter<string> = new EventEmitter<string>();
  @Input() viewOnly : boolean = false;
  @Input() id: any = "time10;"
  timePickerConfig = {
    containerClass: this._tran.currentLang == 'ar' ? ' rtl' : ' '
  }

  constructor(private _tran: TranslateService,private atp: AmazingTimePickerService,private localeService: BsLocaleService, private auth: AuthenticationService)
  {
    //this.auth.GetCurrentuserNameByUserId();
    this.auth.initializeObject(); }

  ngOnInit() {
    this.localeService.use(this.auth.getCurrentculture());
      this.timePickerConfig = {
        containerClass: this._tran.currentLang == 'ar' ? 'rtl' : ''
      }
  }
  OpenTime(){
    //debugger
    const amazingTimePicker = this.atp.open({locale : this.auth.getCurrentculture()});
    amazingTimePicker.afterClose().subscribe(time => {
      console.log(time);
      this.selectedTimeChange.emit(time);
    });
  }
  onChange(val : any){
    //debugger
    this.selectedTimeChange.emit(val);
  }
}
