import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { BsModalRef, BsModalService, BsLocaleService } from "ngx-bootstrap";
import { AuthenticationService } from "../../Services/authentication/authentication.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "alertmessage",
  templateUrl: "./alertmessage.component.html",
  styleUrls: ["./alertmessage.component.css"],
})
export class AlertmessageComponent implements OnInit {
  @Input() alertBody = "";
  @Input() alertActionPressed: Subject<boolean> = new Subject<boolean>();
  @Output() alertConfirmClicked: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @ViewChild("alertConfirm") template;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private _tran: TranslateService,
    private localeService: BsLocaleService,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.localeService.use(this.auth.getCurrentculture());

    this.alertActionPressed.subscribe((isShow) => {
      if (isShow) {
        let config = {
          class:
            this._tran.currentLang == "ar"
              ? " alert-popup rtl"
              : " alert-popup",
        };
        this.modalRef = this.modalService.show(this.template, config);
      }
    });
  }
  confirmClicked() {
    this.alertConfirmClicked.emit(true);
    this.modalRef.hide();
  }
}
